import {
  VerticalCustom as DrillingIcon,
  UsersAltLight as RigCrewIcon,
  DrillingRigCustom as RigIcon,
  SearchLight as SearchIcon,
  StarFill as StarIcon,
  StarLight as StarOutlinedIcon,
  Status as StatusIcon,
  WellClusterLightCustom as WellClusterIcon,
} from '@profgeosoft-ui/icons';
import { Button, Input, Loader, RadioButtonGroup, Scrollbar, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useService, useServiceAccessor } from 'src/packages/di';

import type { WellItemEntity } from './WellCollapse';
import type { WellListWidgetEntity } from './WellListWidget.entity';
import type { WellListWidgetStore } from './WellListWidget.store';
import type {
  IWellCollapseItemEntity,
  WellCollapseItemEntity,
} from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { MouseEvent, Ref } from 'react';
import type { TWellListView } from 'src/api-types/wells.types';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { ContextMenuButton } from '../context-menu-button/ContextMenuButton';
import { WellCollapse } from '../well-collapse/WellCollapse';

import styles from './WellListWidget.module.scss';

const groupingIcons: Partial<Record<string, React.ReactElement>> = {
  'rig-icon': <RigIcon className={styles.groupTypeIcon} />,
  'rig-crew-icon': <RigCrewIcon className={styles.groupTypeIcon} />,
  'status-icon': <StatusIcon className={styles.groupTypeIcon} />,
  'location-icon': <WellClusterIcon className={styles.groupTypeIcon} />,
};

type Props = {
  wellListEntity: WellListWidgetEntity;
  store: WellListWidgetStore;
  refAt?: <U extends HTMLDivElement>(key: number) => Ref<U>;
  onCreateWellLogsWidget?(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
  onWellSelect?(entity: WellCollapseItemEntity): void;
};

export const WellListWidgetBody = observer(function WellListWidgetBody({
  store,
  wellListEntity,
  refAt,
  onCreateWellLogsWidget,
  onWellSelect,
}: Props) {
  const { t } = useTranslation();

  const { theme } = useService('theme');
  const {
    changeFilterBy,
    changeGroupBy,
    isLoading,
    changeSearchValue,
    selectWell,
    toggleFavorite,
    favoritesWells,
    onCollapseKeysChange,
    activeKey,
    fetchWells,
    isInitiated,
    effect,
  } = store;

  const {
    filterBy,
    groupBy,
    searchValue,
    selectedWellID,
    wells,
    view: { item: itemView },
  } = wellListEntity;

  const getDataService = useServiceAccessor('preloadService');

  const handleWellSelect = (entity: WellCollapseItemEntity): void => {
    if (entity.id === wellListEntity.selectedWellID) {
      return;
    }

    selectWell(entity);
    onWellSelect?.(entity);
  };

  const dataService = getDataService();
  const wellListView = dataService.getPreloadedData<TWellListView>('well-list-control');

  const filteringItems = wellListView.filtering.filters.map((item, index) => ({
    label: t(`Labels:${item.name}.label`, { defaultValue: item.name }),
    value: index,
  }));

  const groupingItems = wellListView.grouping.groups.map((group, index) => {
    const { icon, name, fieldId } = group;

    return {
      label: groupingIcons[icon] ? (
        <Tooltip title={t(`Labels:${fieldId}.label`)} children={groupingIcons[icon]} />
      ) : (
        name
      ),
      value: index,
    };
  });

  const _onFavoriteToggle = (e: MouseEvent, item: IWellCollapseItemEntity) => {
    e.stopPropagation();
    toggleFavorite(item);
  };

  const renderButtons = (item: IWellCollapseItemEntity) => (
    <>
      <Button
        icon={
          favoritesWells.hasWell(item) ? (
            <StarIcon className={styles.activeIcon} />
          ) : (
            <StarOutlinedIcon className={styles.icon} />
          )
        }
        variant="flat"
        onClick={(e) => _onFavoriteToggle(e, item)}
        className={styles.iconButton}
      />
      <ContextMenuButton id={item.id} onCreateWellLogsWidget={onCreateWellLogsWidget} />
    </>
  );

  useEffect(() => {
    if (!isInitiated) {
      fetchWells();
    }
  }, [isInitiated, fetchWells]);

  useEffect(effect, [effect]);

  return (
    <div className={styles.wrapper}>
      {isLoading && <Loader className={styles.loader} />}
      <div className={styles.contentWrapper}>
        <RadioButtonGroup
          variant="flat"
          items={filteringItems}
          value={filterBy}
          className={styles.switchContainer}
          itemClassName={styles.switchButton}
          onChange={changeFilterBy}
        />
        <Input
          allowClear
          fieldClassName={styles.search}
          prefix={<SearchIcon className={styles.searchIcon} />}
          value={searchValue}
          inputClassName={styles.searchInput}
          placeholder={t('wellList:search')}
          onChange={(e) => changeSearchValue(e.target.value)}
        />
        <Scrollbar>
          <WellCollapse<WellItemEntity>
            renderIcon={(item) => {
              const color =
                item.status === 'COMPLETED' && theme === 'light'
                  ? 'rgba(64, 81, 134, 1)'
                  : itemView.statusColor[item.status];
              return <DrillingIcon className={styles.drillingIcon} style={{ color: color ?? '#ffffff80' }} />;
            }}
            renderButtons={renderButtons}
            collapses={wells}
            onItemSelect={handleWellSelect}
            refAt={refAt}
            activeId={selectedWellID ?? void 0}
            activeKey={activeKey}
            onChange={onCollapseKeysChange}
          />
        </Scrollbar>
      </div>
      <RadioButtonGroup
        variant="flat"
        items={groupingItems}
        value={groupBy}
        itemClassName={styles.groupTypeButton}
        className={styles.groupTypeContainer}
        onChange={changeGroupBy}
      />
    </div>
  );
});
