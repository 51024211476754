import { Illustration } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './NoWellChosen.module.scss';
export const NoWellChosen = observer(function NoWellChosen() {
  const { t } = useTranslation();
  return (
    <div className={styles.noWellContainer}>
      <div className={styles.imageContainer}>
        <Illustration.EmptyWidget style={{ width: '100%', height: '100%' }} />
      </div>

      <div className={styles.noWellText}>{t('widgets:noWellChosen')}</div>
    </div>
  );
});
